.blog-post {
    cursor: pointer;
    max-width: 800px;
    width: 80%;
    text-align: left;
    margin-bottom: 20px;
  }

.blog-title {
  justify-content: center;
  text-align: center;
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.blog-post-title{
  transition: transform 0.3s;
  color: #A896B2
}

.blog-post-title:hover {
  color: #6a3481;
}

a {
  color: var(--text-color);
}


  