/* navigation bar */
.navBar {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5vw; /* 0 for top and bottom, 20px for left and right */
  background-color: var(--header-bg-color);
  margin-bottom: 0px;
  margin-left: 1%;
  margin-right: 1%;
  border-radius: 8px;
  border-style: solid;
  border-color: var(--header-border-color);
}

.navBar li {
  padding: 2vw;
}

.navBar a {
  text-decoration: none;
  color: var(--text-color);
  
}

.navBarLinks {
  font-size: 24px;  
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  transition: color 0.3s;
}

.navBarLinks:hover {
  transform: scale(1.30);
  color: #A896B2;  /*rgb(155, 202, 142);*/
}

.navBarLinksLi {
  transition: transform 0.3s;
}

.navBarLinksLi:hover {
  transform: scale(1.30);
}

.main-body {
  margin-bottom: 100px;
}

@media screen and (max-width: 601px) {
  .navBarLinks {
    font-size: 18px;
  }
}

