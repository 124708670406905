/**********Pop-Up************/
.project-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 2%;
  }
  
.popup-content {
  display: flex;
  max-width: 950px;
  width: 90%;
  gap: 3%;
}

.popup-img {
  width: 100%;
  max-width: 65%;
  max-height: 400px;
  object-fit: contain;
  height: auto;
  border-radius: 20px;
  align-items: center;
}

.popup-button {
  background-color: transparent;
  color: white;
  border-style: solid;
  border-color: var(--header-border-color);
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
  float:right;
  transition: transform 0.3s;
}

.popup-button:hover {
  transform: scale(1.40);
}

.project-popup a{
  color: white;
}

.popup-text {
  max-height: 400px;
  overflow-y: auto;
  align-items: flex-start;
}

.project-popup h3 {
  margin-bottom: 20px;
}


.project-popup p {
  margin: 1rem 0;
  padding: 0;
  margin-bottom: 40px;
}

.technologies {
  line-height: 14px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

/**********Card************/

.project-page {
  width: 80%;
  margin: auto;
  justify-content: center;
}

.project-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.project-card {
  flex-basis: calc(25% - 20px); /* ~ determines number of cards in a row: 33% fits 3, 50% fits 2 cards */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s;
  background-color: var(--header-bg-color);
  padding-top: 10px;
  border-radius: 20px;
}

.project-card:hover {
  transform: scale(1.10);
}


.project-preview-img {
  height: 175px;
  width: auto;
  border-radius: 8px;
}

h1 {
  text-align: center; 
}

.project-category {
  text-align: center;
}

h3 {
  text-align: center;
}

.project-category-bubble {
  background-color: #A896B2;
  border-radius: 8px;
  padding: 6px;
  display: inline;
}

.technologies-card {
  font-size: 14px;
  line-height: 10px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.shortDescription {
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .popup-img {
    display: none;
  }
}
  