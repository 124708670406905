.home-flex {
  display: flex;
}

.home {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-y: hidden;
}

.about {
  height: 100%;
  flex: 1.3;
  width: 80%;
  align-self: flex-start;
  align-items: center;
  overflow-y: scroll;
}
  
.profile-pic {
  max-width: 325px;
  height: 325px;
  border-radius: 50%;
  object-fit: cover;
  margin: 20px auto;
  transition: transform 0.3s;
}

.profile-pic:hover {
  transform: scale(1.05);
}

.portfolio-link {
  display: inline-block;
  border-radius: 8px;
  border-color: var(--header-border-color);
  border-style: solid;
  padding: 8px;
  cursor: pointer;
  background-color: #d0bcdc;
  color: black;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
}

.portfolio-link:hover {
  background-color: #7f4b8e;
  transition: transform 0.3s;
  transform: scale(1.10);
}

.projectLink {
  display: inline-block; /* Ensure the element is a block-level element so the transform works */
  transition: transform 0.3s;
}

.projectLink:hover {
  transition: transform 0.3s;
  transform: scale(1.05);
}

.Ollie {
  height: auto;
  border-radius: 20px;
  width: 100%;
}

figure {
  margin: 0px;
  max-width: 150px;
}

figcaption {
  text-align: center;
  display: block;
}

@media (max-width: 900px) { 
  .home-flex {
    flex-direction: column;
  }

  .about {
    width: 90%;
    align-self: center;
    align-items: center;
  }

  .home {
    width: 90%;
    align-items: center;
    align-self: center;
  }

  .social-links img {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
  }

  .profile-pic {
    width: 90%;
    max-width: 400px;
    height: 400px;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px 0;
    transition: transform 0.3s;
  }
}




