body {
  margin: 0;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
}

:root {
  --dark-mode-bg: rgb(26, 33, 44);
  --light-mode-bg: white;
  --dark-mode-header: rgb(52,53,65);
  --light-mode-header: #dfe0e1;
  --dark-mode-header-border: #696969;
  --light-mode-header-border: 	#BEBEBE;
  --dark-mode-text: white;
  --light-mode-text: black;
  --dark-mode-gh-icon: /github-mark-white.png;
  --light-mode-gh-icon: /github-mark-dark.png;

  --header-bg-color: var(--dark-mode-header);
  --header-border-color: var(--dark-mode-header-border);
  --bg-color: var(--dark-mode-bg);
  --text-color: var(--dark-mode-text);
  --github-icon: var(--dark-mode-gh-icon);
}

html {
  overflow-y: scroll;  
  scrollbar-width: thin;
}

::-webkit-scrollbar{
  background: var(--bg-color);
  width: 12px;
}

/* this should change the thumb color */
::-webkit-scrollbar-thumb {
  background:  #A896B2
}

.tags{
  background-color: #A896B2;
  border-radius: 8px;
  padding: 6px;
  margin: 6px;
  white-space: nowrap;
}

.tag-image {
  height: 45px;
  margin-top: 6px;
  margin-right: 6px;
  border-radius: 4px;
}

.tagsLabel {
  display: block;
  font-weight: bold;
  text-align: center;
  background-color: var(--header-border-color);
  border-radius: 8px;
  padding: 6px;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.social-links img {
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.links {
  transition: transform 0.3s;
}

.links:hover {
    transform: scale(1.40);
  }