.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    background-color: var(--header-bg-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    border-radius: 8px;
    gap: 2%;
    border-style: solid;
    border-color: var(--header-border-color);
}

.rights {
    justify-content: left;
    padding-left: 10%;
}

.switch-container {
    display: flex;
    align-items: center;
    padding-right: 10%;
}

.switch-label {
    align-items: center;
    padding-right: 10px;
}

/* CSS Slider Properties from w3 */

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    min-width: 60px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }